// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
    let errors = {};
    console.log("valuevaluevalue", value);
    let emailRegex =
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let nameRegex = /^[a-zA-Z]+$/;
    if (isEmpty(value.firstname)) {
        errors.firstname = "First Name Required";
    } else {
        if (!nameRegex.test(value.firstname)) {
            errors.firstname = "Only Albhabets Allowed";
        }
    }
    if (isEmpty(value.lastname)) {
        errors.lastname = "Last Name Required";
    } else if (!nameRegex.test(value.lastname)) {
        errors.lastname = "Only Albhabets Allowed";
    }
    if (isEmpty(value.message)) {
        errors.message = "Message Required";
    }
    if (isEmpty(value.email)) {
        errors.email = "Email Required";
    } else if (!emailRegex.test(value.email)) {
        errors.email = "Invalid email";
    }

    if (isEmpty(value.reCaptcha)) {
        errors.reCaptcha = "Required";
    }

    return errors;
};

export default validation;